import jwt_decode from 'jwt-decode';

export const setToken = (token) => {
    localStorage.setItem('session-token', token);
}

export const setIsLogged = (status) => {
    localStorage.setItem('is-logged', status);
}

export const setUserDetails = (data) => {
    let userDetails = {
        user_id: data.user_id,
        email: data.email,
        first_name: data.first_name,
        full_name: data.full_name,
        profile_picture: data.profile_picture,
        user_theme: data.user_theme
    }
    localStorage.setItem('user-details', JSON.stringify(userDetails));
}

export const setUserRoles = (data) => {
    localStorage.setItem('user-roles', JSON.stringify(data));
}

export const setActiveRole = (data) => {
    data.forEach(element => {
        if (element.is_default === 1) {
            localStorage.setItem('active-role', JSON.stringify(element));
        }
    });
}

export const switchRole = (roleId) => {
    let storage = localStorage.getItem('user-roles');
    if (storage != null) {
        let userRoles = JSON.parse(localStorage.getItem('user-roles'));
        userRoles.forEach(element => {
            if (element.role_id === roleId) {
                localStorage.setItem('active-role', JSON.stringify(element));
            }
        });
    }
    return true;
}

export const checkPermission = (key) => {
    let storage = localStorage.getItem('active-role');
    if (storage != null) {
        let activeRole = JSON.parse(localStorage.getItem('active-role'));
        let privileges = activeRole.role_privileges;

        return privileges[key] ? privileges[key] : false;
    } else {
        return false;
    }
}

export const checkUserPrivilege = (privileges, key) => {
  return privileges.includes(key);
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user-details'));
}

export const getRole = () => {
    return JSON.parse(localStorage.getItem('active-role'));
}

export const getUserRoles = () => {
    return JSON.parse(localStorage.getItem('user-roles'));
}

export const getIsLogged = () => {
    return localStorage.getItem('is-logged') || false;
}

export const checkLogin = () => {
    var jwt = window.localStorage.getItem('session-token');
    if (jwt && getIsLogged()) {
        var decoded = jwt_decode(jwt);
        if (Date.now() / 1000 > decoded.exp) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export const logout = () => {
    if(getIsLogged()){
        localStorage.clear();
        window.location.reload();
    }
}

/////// find in array by value
export const findInArray = (value, array = []) => {
  if (array.length > 0) {
    const foundIndex = array.findIndex(item => item === value);
    if (foundIndex >= 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }

  return false;
}
/////// find in array by value

/////// Function to find an object based on a specific value
export const findInArrayObject = (value, array, key) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return true; // value found
    }
  }
  return false; // Value not found
}
/////// Function to find an object based on a specific value

////// remove value from array by value
export const removeValueFromArray = (value, array = []) => {
  return array.filter(item => item !== value);
};
////// remove value from array

////// remove value from array by index
export const removeValueFromArrayByIndex = (index, array = []) => {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1, array.length)
  ];
  // return newArray.splice(index, 1);
};
////// remove value from array by index

////// remove object from array by value
export const removeObjectFromArray = (value, array, key) => {
  return  array.filter(item => item[key] !== value);
};
////// remove object from array by value
